import React from "react";
import { Box, Grid, Image, Link, Text } from "@pingux/astro";

import { FooterNavItemProps, LinkProps } from "@components/data/footerData";
import { globalSX } from "shared/styles";

import { footerSX } from "@components/Footer/footerStyles";

export interface FooterProps {
  items?: FooterNavItemProps[][];
  logo?: string;
  copyrightText?: string;
  footerBottomLinks?: LinkProps[];
}

const Footer = (props: FooterProps) => {
  const {
    items: footerNavItems,
    logo,
    copyrightText,
    footerBottomLinks,
  } = props;
  return (
    <Box as="footer" sx={footerSX.footerContainer}>
      <Box sx={{ ...globalSX.container, bg: "gray-100" }}>
        <Box sx={{ ...globalSX.wrapper, ...globalSX.headerFooterWrapper }}>
          {logo && (
            <Box py="lg">
              <Box py="sm" px="md">
                <Image
                  src={logo}
                  sx={footerSX.logo}
                  alt="logo"
                  aria-label="logo"
                />
              </Box>
            </Box>
          )}

          {footerNavItems && (
            <Grid columns={[2, 2, 4, 5, 5, 5]} gap={["30px"]}>
              {footerNavItems.map((col: FooterNavItemProps[]) => (
                <Box>
                  {col.map((section: FooterNavItemProps) => (
                    <Box as="ul" mb="lg">
                      {section.header && (
                        <Box as="li">
                          <Text sx={footerSX.footerHeader}>
                            {section.header}
                          </Text>
                        </Box>
                      )}
                      {section.links.map((link) => (
                        <Box as="li">
                          <Link {...link.linkProps} sx={footerSX.navLink} />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              ))}
            </Grid>
          )}

          {copyrightText && (
            <Box mb="sm" textAlign="center">
              <Text sx={footerSX.footerText}>{copyrightText}</Text>
            </Box>
          )}

          {footerBottomLinks && (
            <Grid
              columns={[
                "repeat(2, max-content)",
                "repeat(2, max-content)",
                "repeat(auto-fit, minmax(max-content, 1px))",
              ]}
              sx={footerSX.footerBottomLinkContainer}
              as="ul"
              gap="0"
            >
              {footerBottomLinks.map((link) => (
                <Box as="li">
                  <Link {...link} sx={footerSX.footerBottomLinks} />
                </Box>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
