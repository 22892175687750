import { AuthStateInterface, ActionInterface } from "./types";

// Initial state with the correct type
export const initialState: AuthStateInterface = {
  isAuthenticated: true, // Default value, can be set to true if needed
  user: { name: "", email: "" },
};

// Reducer function with typed state and action
const authReducer = (
  state: AuthStateInterface = initialState,
  action: ActionInterface
) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, isAuthenticated: true };
    case "LOGOUT":
      return { ...state, isAuthenticated: false };
    default:
      return state;
  }
};

export default authReducer;
