import { Box, Loader } from "@pingux/astro";
import { COLORS } from "@assets/styles/styleConstants";
import "./index.scss";

const PageLoader = () => {
  return (
    <Box className="overlay" isRow alignItems="center" justifyContent="center">
      <Loader size={"sm"} color={COLORS.gray700} />
    </Box>
  );
};

export default PageLoader;
