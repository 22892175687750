import {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  Dispatch,
} from "react";
import authReducer, { initialState } from "state/reducers/authReducer";
import { AuthStateInterface, ActionInterface } from "state/reducers/types";

interface authContextProps {
  state: AuthStateInterface;
  dispatch: Dispatch<ActionInterface>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<authContextProps>({
  state: initialState,
  dispatch: () => undefined,
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
