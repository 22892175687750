import { Box, Link, Icon } from "@pingux/astro";
import OpenInNewIcon from "@pingux/mdi-react/OpenInNewIcon";

const LinkWithIcon = ({ href, label }: { href: string; label: string }) => {
  const handleExternalRedirect = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <Box isRow gap="sm">
      <Link onPress={() => handleExternalRedirect(href)}>{label}</Link>
      <Icon
        color="primary"
        icon={OpenInNewIcon}
        size="sm"
        title={{ name: "Open In New Icon" }}
      />
    </Box>
  );
};

export default LinkWithIcon;
