import { lazy } from "react";
const HomePage = lazy(() => import("@pages/HomePage"));
const LoginPage = lazy(() => import("@pages/Login"));
const AboutPage = lazy(() => import("@pages/About"));
const ItemDetailsPage = lazy(
  () => import("@pages/ItemDetailsPage/ItemDetailsPage")
);
const AuthorDetailPage = lazy(() => import("@pages/AuthorDetailPage"));
import { pageData } from "@components/data/pageData";

const PublicRoutes = [
  {
    path: "/",
    element: <LoginPage />,
    restricted: true, // restriced flag in public routes indicate that only non-authenticated user can access this route
  },
  {
    path: "/login",
    element: <LoginPage />,
    restricted: true,
  },
  {
    path: "/policy",
    element: <h1>Policy</h1>,
    restricted: false,
  },
];

const PrivateRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/item/:id",
    element: <ItemDetailsPage {...pageData} />,
  },
  { path: "/author/:id", element: <AuthorDetailPage /> },
];

export { PublicRoutes, PrivateRoutes };
