import { LinkProps as AstroLinkProps } from "@pingux/astro";

export interface LinkProps extends AstroLinkProps {
  "aria-label"?: string;
}

export interface FooterNavItemProps {
  header: string;
  links: { linkProps: LinkProps }[];
}

export const footerLogo = "./ping-logo-horizontal-black.svg";

export const footerNavLinks: FooterNavItemProps[][] = [
  [
    {
      header: "Solutions",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "Engage Your Customers",
            children: "Engage Your Customers",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Secure Your Workforce",
            children: "Secure Your Workforce",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Decentralized Identity",
            children: "Decentralized Identity",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Passwordless Authentication",
            children: "Passwordless Authentication",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Implement Zero Trust",
            children: "Implement Zero Trust",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Migrate to the Cloud",
            children: "Migrate to the Cloud",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Modernize Legacy IAM",
            children: "Modernize Legacy IAM",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Prevent Online Fraud",
            children: "Prevent Online Fraud",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Innovate Digital Experiences",
            children: "Innovate Digital Experiences",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Privacy and Consent",
            children: "Privacy and Consent",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Mergers and Acquisitions",
            children: "Mergers and Acquisitions",
          },
        },
      ],
    },
  ],
  [
    {
      header: "Products",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "PingOne Cloud Platform",
            children: "PingOne Cloud Platform",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "PingOne for Customers",
            children: "PingOne for Customers",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "PingOne for Workforce",
            children: "PingOne for Workforce",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "FedRAMP Solutions for Government",
            children: "FedRAMP Solutions for Government",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "PingOne Neo",
            children: "PingOne Neo",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Orchestration",
            children: "Orchestration",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Threat Protection",
            children: "Threat Protection",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Directory",
            children: "Directory",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Identity Management",
            children: "Identity Management",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Identity Governance",
            children: "Identity Governance",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Single Sign-on (SSO)",
            children: "Single Sign-on (SSO)",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Multi-Factor Authentication (MFA)",
            children: "Multi-Factor Authentication (MFA)",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Identity Verification",
            children: "Identity Verification",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Authorization",
            children: "Authorization",
          },
        },
      ],
    },
  ],
  [
    {
      header: "Resources",
      links: [
        { linkProps: { href: "#", "aria-label": "Blog", children: "Blog" } },
        {
          linkProps: {
            href: "#",
            "aria-label": "Integration Directory",
            children: "Integration Directory",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Software & Mobile App Downloads",
            children: "Software & Mobile App Downloads",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Explore the Content Library",
            children: "Explore the Content Library",
          },
        },
      ],
    },
    {
      header: "Developers",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "Developer Community Forum",
            children: "Developer Community Forum",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Developer Tools",
            children: "Developer Tools",
          },
        },
        {
          linkProps: { href: "#", "aria-label": "DevOps", children: "DevOps" },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Cloud Containerization",
            children: "Cloud Containerization",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Ping Identity Github",
            children: "Ping Identity Github",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Ping Identity Docker Hub",
            children: "Ping Identity Docker Hub",
          },
        },
      ],
    },
  ],
  [
    {
      header: "Support",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "Support Portal",
            children: "Support Portal",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Professional Services",
            children: "Professional Services",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Contact Us",
            children: "Contact Us",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Training & Certification for Current Customers",
            children: "Training & Certification for Current Customers",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Community Q&A Forum",
            children: "Community Q&A Forum",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Create a Case",
            children: "Create a Case",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Documentation",
            children: "Documentation",
          },
        },
      ],
    },
    {
      header: "Partners",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "Partners Main",
            children: "Partners Main",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Partner Portal Login",
            children: "Partner Portal Login",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Find a Partner",
            children: "Find a Partner",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Become a Partner",
            children: "Become a Partner",
          },
        },
      ],
    },
  ],
  [
    {
      header: "Company",
      links: [
        {
          linkProps: {
            href: "#",
            "aria-label": "About Us",
            children: "About Us",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Corporate Social Responsibility",
            children: "Corporate Social Responsibility",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Leadership Team",
            children: "Leadership Team",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Careers",
            children: "Careers",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Ping Newsroom",
            children: "Ping Newsroom",
          },
        },
        {
          linkProps: {
            href: "#",
            "aria-label": "Upcoming Events",
            children: "Upcoming Events",
          },
        },
      ],
    },
  ],
];

const currentYear = new Date().getFullYear();
export const copyrightText = `Copyright © 2010-${currentYear} Ping Identity, all rights reserved.`;

export const footerBottomLinks: LinkProps[] = [
  { href: "#", children: "Report a Problem", "aria-label": "Report a Problem" },
  { href: "#", children: "Terms of Use", "aria-label": "Terms of Use" },
  { href: "#", children: "Privacy Policy", "aria-label": "privacy Policy" },
  { href: "#", children: "Cookie Policy", "aria-label": "Cookie Policy" },
  { href: "#", children: "Sitemap", "aria-label": "Sitemap" },
];
