import {
  COLORS,
  SPACING,
  DIMENSIONS,
  BOX_SHADOW,
} from "@assets/styles/styleConstants";
import "@assets/styles/global.scss";

const defaultCardStyle = {
  border: "none",
  boxShadow: BOX_SHADOW.none,
};

const card = {
  p: "0",
  color: COLORS.gray900,
  backgroundClip: "border-box",
  border: "1px solid",
  borderColor: COLORS.gray300,
  borderRadius: DIMENSIONS.borderRadius,
  boxShadow: BOX_SHADOW.none,
  "&:hover": {
    borderColor: COLORS.blueHover,
  },
};

const cardFooter = {
  borderTop: "none",
};

const cardIcon = {
  justifyContent: "center",
  alignItems: "center",
  minWidth: DIMENSIONS.iconSize,
  height: DIMENSIONS.iconSize,
  width: DIMENSIONS.iconSize,
  borderRadius: ".25rem",
};

const avatarSize = {
  justifyContent: "center",
  alignItems: "center",
  minWidth: DIMENSIONS.avatarSize,
  height: DIMENSIONS.avatarSize,
  width: DIMENSIONS.avatarSize,
  borderRadius: ".25rem",
};

const commonLoadingStyle = {
  opacity: 0.7,
  animation: "loading 1.5s linear infinite alternate",
  borderRadius: "0.125rem",
};
const cardIconLoading = {
  ...cardIcon,
  ...commonLoadingStyle,
  minWidth: "48px",
  height: "48px",
  width: "48px",
  marginBottom: "1.5rem",
  borderRadius: ".25rem",
};

const headerIconLoading = {
  ...avatarSize,
  ...commonLoadingStyle,
  minWidth: "104px",
  height: "104px",
  width: "104px",
  marginBottom: "1.5rem",
  borderRadius: ".25rem",
};

const truncateText = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: "body",
};

const truncateTextLoading = {
  ...commonLoadingStyle,
  width: "10rem",
  height: "1rem",
};

const truncateLongTextLoading = {
  ...commonLoadingStyle,
  width: "25rem",
  height: "1rem",
};

const truncateLongLongTextLoading = {
  ...commonLoadingStyle,
  width: "45rem",
  height: "1rem",
};

const TwoLinesTruncate = {
  ...truncateText,
  whiteSpace: "normal",
  WebkitBoxOrient: "vertical",
  display: "-webkit-box",
  WebkitLineClamp: "2",
};

const cardContentRowLoading = {
  ...commonLoadingStyle,
  width: "100%",
  height: "1rem",
};

const cardContentLastRowLoading = {
  ...commonLoadingStyle,
  width: "75%",
  height: "1rem",
};

const cardBadgeLoading = {
  ...commonLoadingStyle,
  width: "20%",
  height: "1rem",
};

const CallToActionCard = {
  ...defaultCardStyle,
  bg: COLORS.blue100,
  justifyContent: "center",
  alignItems: "flex-start",
  mb: SPACING.large,
};

export const cardSX = {
  card,
  cardFooter,
  cardIcon,
  cardIconLoading,
  headerIconLoading,
  truncateText,
  truncateTextLoading,
  truncateLongTextLoading,
  truncateLongLongTextLoading,
  cardContentRowLoading,
  cardContentLastRowLoading,
  cardBadgeLoading,
  TwoLinesTruncate,
  CallToActionCard,
};
