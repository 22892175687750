import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Image,
  NavigationHeader,
  Link,
  Separator,
  Text,
  IconButtonToggle,
  OverlayProvider,
} from "@pingux/astro";
import { menuItems } from "@components/data/headerFile";
import HelpButton from "./HelpButton";
import WhiteBalanceSunnyIcon from "@pingux/mdi-react/WhiteBalanceSunnyIcon";
import MoonWaningCrescentIcon from "@pingux/mdi-react/MoonWaningCrescentIcon";
import { BASE_URL, MY_CONTENT } from "shared/constants/routes";
import { globalSX } from "shared/styles";
import { headerSX } from "./headerStyles";

interface HeaderProps {
  headerText?: string;
  logo?: string;
  isFixed?: boolean;
  userData?: {
    name?: string;
    email?: string;
    image?: string;
  };
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const { logo, isFixed } = props;
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);

  const toggleSignIn = () => {
    setIsLoggedIn(!isLoggedIn);
  };

  const goToMyContent = () => {
    navigate(`${BASE_URL}/'${MY_CONTENT}'`);
  };

  return (
    <NavigationHeader
      sx={headerSX.headerContainer}
      isSticky
      aria-labelledby="next-gen-header"
    >
      <Link
        href="/home"
        variant="navigationHeader.logoBand"
        onPress={() => navigate(`${BASE_URL}/home`)}
      >
        <Box
          isRow
          alignItems="center"
          justifyContent="center"
          py="sm"
          flex="0 0 auto"
        >
          <Image
            src={logo}
            alt="Ping Identity Logo"
            mr="md"
            sx={{
              height: "24px",
            }}
          />
          <Separator
            orientation="vertical"
            style={{
              height: "28px",
              margin: "0",
            }}
          />
          <Text
            as="h2"
            variant="navigationHeader.headerPlaceholder"
            id="next-gen-header"
          >
            Marketplace
          </Text>
        </Box>
      </Link>
      <OverlayProvider>
        <Box as="ul" isRow alignItems="center" p="0" ml="auto" flex="0 0 auto">
          <HelpButton items={menuItems} />
          <Box as="li">
            <IconButtonToggle
              toggledIcon={WhiteBalanceSunnyIcon}
              defaultIcon={MoonWaningCrescentIcon}
              iconProps={{
                size: "24px",
              }}
              buttonProps={{
                "aria-label": "theme icon",
                variant: "headerNav",
              }}
            />
          </Box>
        </Box>
      </OverlayProvider>
    </NavigationHeader>
  );
};

export default Header;
