export const COLORS = {
  gray100: "#f3f4f6",
  gray300: "#d1d5db",
  gray700: "#374151",
  gray800: "#1f2937",
  gray900: "#111827",
  blackE6: "#000000e6",
  white: "#ffffff",
  blue100: "#ebf4ff",
  blueHover: "#1a73e8",
};

export const SPACING = {
  small: "sm",
  medium: "md",
  large: "lg",
  px64: "64px",
  margin64: "64px",
};

export const FONT = {
  small: "sm",
  large: "lg",
  mediumWeight: "2",
  lightWeight: "1",
};

export const DIMENSIONS = {
  logoHeight: "24px",
  headerHeight: "4.5rem",
  borderRadius: "1rem",
  iconSize: "48px",
  avatarSize: "104px",
};

export const Z_INDEX = {
  header: 1000,
};

export const BOX_SHADOW = {
  none: "none",
};

export const TRANSITION = {
  collapse: "max-height 0.5s ease-out",
};

export const OPACITY = {
  semiTransparent: "0.5",
};
