import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "state/context/AuthContext";
interface PublicRouteProps {
  restricted: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ restricted, ...rest }) => {
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  if (isAuthenticated && restricted) {
    return <Navigate to="/home" replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
