import { useNavigate } from "react-router-dom";
import { Box, Icon, Link, Text } from "@pingux/astro";
import LinkWithIcon from "@components/LinkWithIcon";
import { aboutCardStyle } from "./aboutCardStyles";
import { cardSX } from "@components/ItemCard/cardSX";

export type AboutCardProps = {
  authorId: string;
  developer: string;
  developerHref?: string;
  supportHref: string;
  companyWebsite: string;
  aboutText?: string;
  goToAuthorPage?: (id?: string | number) => void;
};

const AboutCard = ({
  authorId,
  developer,
  companyWebsite,
  supportHref,
  developerHref,
  aboutText,
  goToAuthorPage,
}: AboutCardProps) => {
  return (
    <Box sx={aboutCardStyle}>
      <Text variant="h4">About the Developer</Text>
      <Box gap="sm">
        <Link sx={{ fontWeight: 2 }} onPress={goToAuthorPage}>
          {developer}
        </Link>
        <Text lineHeight="1.5" sx={cardSX.TwoLinesTruncate}>
          {aboutText ? aboutText : ""}
        </Text>
      </Box>
      <LinkWithIcon href={supportHref} label="Support" />
      <LinkWithIcon href={companyWebsite} label="Visit Website" />
    </Box>
  );
};

export default AboutCard;
