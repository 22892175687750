import QuestionMarkCircleOutlineIcon from "@pingux/mdi-react/QuestionMarkCircleOutlineIcon";
import { Box, IconButton, PopoverMenu, Item, Menu, Icon } from "@pingux/astro";
import { headerSX } from "./headerStyles";

interface DropdownProps {
  items: { label: string; url: string }[];
}

const HelpButton: React.FC<DropdownProps> = ({ items }) => {
  const handleExternalRedirect = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <PopoverMenu>
        <IconButton aria-label="Help">
          <Icon
            icon={QuestionMarkCircleOutlineIcon}
            size="md"
            title={{
              name: "Help Icon",
            }}
            variant=""
          />
        </IconButton>
        <Menu>
          {items.map((item) => (
            <Item
              key={item.label}
              onClick={() => handleExternalRedirect(item.url)}
            >
              {item.label}
            </Item>
          ))}
        </Menu>
      </PopoverMenu>
    </Box>
  );
};

export default HelpButton;
