import React from "react";
import { Box, Image, Link, Separator, Text } from "@pingux/astro";

export const UsefulLinks = () => {
  return (
    <Box gap="sm">
      <Text variant="h4">Useful Links</Text>
      <Box as="ul" sx={{ lineHeight: "28px", mb: "xl" }}>
        <li>
          <Text>
            <Link href="www.pingidentity.com">Enim ad minim veniam </Link>
            tempor incididunt.
          </Text>
        </li>
        <li>
          <Text>
            Minim veniam
            <Link href="www.pingidentity.com"> dolore magna aliqua.</Link>
          </Text>
        </li>
      </Box>
      <Separator />
    </Box>
  );
};

export const HeroImage = () => {
  return (
    <Image
      sx={{
        width: "100%",
        height: "500px",
        borderRadius: "16px",
      }}
      alt="hero image"
      src="https://app.requestly.io/delay/10/https://picsum.photos/1000/1000"
    />
  );
};
