import React from "react";
import { TabListItemProps } from "@pingux/astro";
import GearStopIcon from "@pingux/mdi-react/GearStopIcon";
import HammerWrenchIcon from "@pingux/mdi-react/HammerWrenchIcon";

import { HeroImage, UsefulLinks } from "../UsefulLink/UsefulLink";
import OverViewPageTemplate from "../ResourcePageTemplate/ResourcePageTemplate";
// import ReviewWidget from '../../components/IndividualPageLayout/ReviewWidget/ReviewWidget';

const headerProps = {
  icon: HammerWrenchIcon,
  author: "Ping Identity",
  category: "Utility",
  rating: "4.0",
  downloadCount: "171k+",
  appName: "DaVinci Linter",
  avatarColor: "pink-100",
  avatarIconColor: "pink-600",
  authorId: "PingIdentity",
};

const isLoading = false;
const loremText =
  "Welcome to the DaVinci Flow Design Studio, a lightweight Chrome Extension designed for customizing Ping Identity DaVinci Flows that leverage Bootstrap CSS. This tool simplifies rebranding, offering a user-friendly interface to modify logos, background images, fonts, and more. Whether you're a developer streamlining the process or a designer enhancing the out-of-the-box experience, the Design Studio helps to deliver a polished and personalized touch.";

const features = [
  "Enim ad minim veniam",
  "Dolore magna aliqua",
  "Consectetur adipisicing elit",
];

const categories = ["Authentication", "Journey"];

const developerProps = {
  authorId: "PingIdentity",
  developer: "Ping Identity",
  // aboutText:
  //   "Welcome to the DaVinci Flow Design Studio, a lightweight Chrome Extension designed for customizing Ping Identity DaVinci Flows that leverage Bootstrap CSS. This tool simplifies rebranding, offering a user-friendly interface to modify logos, background images, fonts, and more. Whether you're a developer streamlining the process or a designer enhancing the out-of-the-box experience, the Design Studio helps to deliver a polished and personalized touch.",
  supportHref: "www.pingidentity.com",
  companyWebsite: "www.pingidentity.com",
  developerHref: "www.pingidentity.com",
};

const versionProps = {
  version: "1.2.1",
  releaseDate: "10/4/2024",
  updateDate: "11/6/2024",
};

const overviewProps = {
  developerProps,
  versionProps,
  categories,
  features,
  description: loremText,
  enablementDescription: loremText,
  isLoading,
};

const reviewsArray = [
  {
    name: "Liza Cliffburry",
    imageSrc: "http://placekitten.com/200/200",
    date: "December 6, 2024",
    review:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium ratione ab porro reprehenderit accusantium soluta corporis minima sequi aut, veritatis quam ipsum, vitae incidunt eveniet odit quia animi modi obcaecati.",
  },
  {
    name: "Fatima Singleton",
    imageSrc: "http://placekitten.com/200/200",
    date: "November 20, 2022",
    review:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium ratione ab porro reprehenderit accusantium soluta corporis minima sequi aut, veritatis quam ipsum, vitae incidunt eveniet odit quia animi modi obcaecati.",
  },
];

const reviewProps = {
  reviews: reviewsArray,
};

const tabs: TabListItemProps[] = [
  {
    name: "Overview",
    children: (
      <OverViewPageTemplate
        {...overviewProps}
        slots={{ bottomSlot: <UsefulLinks /> }}
      />
    ),
  },
  // { name: 'Reviews', children: <ReviewWidget {...reviewProps} /> },
];

const secondTabs: TabListItemProps[] = [
  {
    name: "Overview",
    children: (
      <OverViewPageTemplate
        slots={{ topSlot: <HeroImage />, bottomSlot: <UsefulLinks /> }}
        {...overviewProps}
      />
    ),
  },
  // { name: 'Reviews', children: <ReviewWidget {...reviewProps} /> },
];

export const pageData = {
  headerProps,
  tabs,
};

export const secondPageData = {
  headerProps: {
    ...headerProps,
    icon: GearStopIcon,
    appName: "Auto Closing Window",
    category: "DaVinci Node",
    avatarColor: "cyan-100",
    avatarIconColor: "cyan-700",
  },
  tabs: secondTabs,
};
