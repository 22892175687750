export const headerLogo = "./ping-logo-horizontal.svg";
export const headerText = "Marketplace";
export const isFixed = true;

export const menuItems = [
  { label: "Help & Support", url: "https://support.pingidentity.com/s/" },
  { label: "Docs", url: "https://docs.pingidentity.com/" },
  { label: "Developer", url: "https://developer.pingidentity.com/en.html" },
  { label: "Labs", url: "https://labs.pingidentity.com/en.html" },
];

//mock userdata
export const userData = {
  name: "Barbara Jensen",
  email: "bjensen@example.com",
  image: "./user.png",
};
