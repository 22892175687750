import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./routes";
import { AuthProvider } from "state/context/AuthContext";

const App = () => {
  return (
    <AuthProvider>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </AuthProvider>
  );
};

export default App;
