import { Box, Text } from "@pingux/astro";
import { versionCardStyle } from "./versionCardStyles";

export type VersionCardProps = {
  version: string;
  releaseDate: string;
  updateDate: string;
};

type ValuePairProps = {
  title: string;
  data: string;
};

const ValuePair = ({ title, data }: ValuePairProps) => (
  <Box>
    <Text as="small" variant="small">
      {title}
    </Text>
    <Text>{data}</Text>
  </Box>
);

const VersionCard = ({
  version,
  releaseDate,
  updateDate,
}: VersionCardProps) => (
  <Box sx={versionCardStyle}>
    <ValuePair title="Version" data={version} />
    <ValuePair title="Released" data={releaseDate} />
    <ValuePair title="Updated" data={updateDate} />
  </Box>
);

export default VersionCard;
