import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "state/context/AuthContext";

const PrivateRoute: React.FC = () => {
  const {
    state: { isAuthenticated, user },
  } = useAuthContext();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
