import Header from "@components/Header";
import Footer from "@components/Footer";
import { Box } from "@pingux/astro";
import { globalSX } from "shared/styles/index";
import { headerLogo, headerText } from "@components/data/headerFile";
import {
  copyrightText,
  footerBottomLinks,
  footerLogo,
  footerNavLinks,
} from "@components/data/footerData";
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box as="main" sx={{ ...globalSX.container }}>
      <Header logo={headerLogo} headerText={headerText} />
      {children}
      <Footer
        items={footerNavLinks}
        logo={footerLogo}
        copyrightText={copyrightText}
        footerBottomLinks={footerBottomLinks}
      />
    </Box>
  );
};

export default Layout;
